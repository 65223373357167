// src/App.js

import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import SplashPage from "./components/SplashPage";
import SuccessPage from "./components/SuccessPage";
import "./global.css"; // Import the global CSS file

function App() {
	return (
		<Router>
			<div className="container">
				<Routes>
					<Route
						path="*"
						element={<Navigate to="/" />}
					/>
					<Route
						path="/"
						element={<SplashPage />}
					/>
					<Route
						path="/success"
						element={<SuccessPage />}
					/>
				</Routes>
			</div>
		</Router>
	);
}

export default App;
