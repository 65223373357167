// src/components/FormInput.js

import React from "react";

const FormInput = ({
	label,
	type,
	value,
	onChange,
	required,
	pattern,
	placeholder,
}) => (
	<label style={{ display: "block", marginBottom: "10px" }}>
		{label}
		<input
			type={type}
			value={value}
			onChange={onChange}
			required={required}
			pattern={pattern}
			placeholder={placeholder}
			style={{
				display: "block",
				width: "100%",
				padding: "8px",
				marginTop: "5px",
			}}
		/>
	</label>
);

export default FormInput;
