// src/components/SuccessPage.js
import React from "react";
import './SuccessPage.css'; // Import the SuccessPage CSS


const SuccessPage = () => {
	return (
		<div className="success-container">
			<h4>Thanks! We&apos;re excited to celebrate with you </h4>
		</div>
	);
};

export default SuccessPage;
